import React from 'react';
import { PageLayout } from '../components/pageLayout';
import {
	Flex,
	Heading,
	Text,
	Box,
	css,
	Image,
	FlexProps,
} from '@chakra-ui/core';
import { graphql } from 'gatsby';
import { BlogPreviewPanel } from '../components/BlogPreviewPanel';
import { CTALink } from '../components/ctaLink';

interface ITextHeader {
	title: string;
	bodyText?: string;
	ctaLink?: URL | string;
	ctaLinkText?: string;
	rest?: FlexProps;
}

export const TextHeader = ({
	title,
	bodyText,
	ctaLink,
	ctaLinkText,
	...rest
}: ITextHeader) => {
	return (
		<Flex
			flexDir={{ base: 'column', lg: 'row' }}
			justifyContent="center"
			w="100%"
			bg="#f7f0e3"
			alignItems="center"
			mb={10}
			{...rest}
			py={20}
		>
			<Box
				px={{ base: 10, lg: 20 }}
				my={{ base: 10, lg: 0 }}
				maxW="75ch"
				// flexWrap="wrap"
				// overflowWrap="anywhere"
				css={css`
					h1 {
						font-size: 3rem;
						font-weight: 700;
					}
				`}
				// textAlign="center"
			>
				<h1>{title}</h1>
				{bodyText}
				{ctaLink && (
					<CTALink
						d="block"
						text={ctaLinkText || 'Read Article'}
						href={ctaLink}
					/>
				)}
				{/* <StyledRichText render={el.text} linkResolver={linkResolver} /> */}
			</Box>
		</Flex>
	);
};
