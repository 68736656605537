import {
	Flex,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Heading,
	Button,
	Box,
} from '@chakra-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { Container } from '../components/Container';
import { PageLayout } from '../components/pageLayout';
import { StyledRichText } from '../components/StyledRichText';
import { TextHeader } from '../components/TextHeader';
import linkResolver from '../utils/linkResolver';

interface Props {}

export const query = graphql`
	query {
		prismic {
			allTerms_and_conditionss {
				edges {
					node {
						sub_title
						title
						body {
							section_body
							index
						}
						_meta {
							lastPublicationDate
						}
					}
				}
			}
		}
	}
`;

const termsAndConditions = ({ data }: Props) => {
	const prismicContent = data.prismic.allTerms_and_conditionss!.edges[0].node;

	return (
		<PageLayout justify="center">
			<TextHeader
				title={prismicContent.title[0].text || 'Terms and Conditions'}
				bodyText={prismicContent.sub_title[0].text}
			/>
			<Container>
				<Tabs orientation="vertical" variant="soft-rounded" variantColor="gray">
					<Flex flexDir={{ base: 'column', md: 'row' }} justifyContent="start">
						<Flex
							px={2}
							pr={8}
							mr={8}
							borderRight={{ base: 'none', md: '1px solid #E2E8F0' }}
							flexDir="column"
							mb={8}
						>
							<Heading color="gray.500" size="lg" mb={3}>
								Contents
							</Heading>
							{prismicContent.body.map((el, i) => {
								// return <Tab key={el.index}>{el.index}</Tab>;
								return (
									<Button
										as="a"
										href={'#' + i}
										rounded="60px"
										mb={2}
										width="min-content"
									>
										{el.index}
									</Button>
								);
							})}
						</Flex>

						<Box minH="384px" maxW="75ch" px={4}>
							<Box>
								{prismicContent.body.map((el, i) => {
									return (
										<Box mb={6}>
											<Heading id={i}>{el.index}</Heading>
											<StyledRichText
												linkResolver={linkResolver}
												render={el.section_body}
											/>
										</Box>
									);
								})}
							</Box>
						</Box>
					</Flex>
				</Tabs>
			</Container>
		</PageLayout>
	);
};

export default termsAndConditions;
